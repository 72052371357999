<template>
  <v-dialog
    v-model="dialog"
    content-class="modal-window-container"
    @input="onDialogInput()"
  >
    <template #activator="{ on }">
      <v-btn
        rounded
        depressed
        color="primary"
        data-cy="request-callback-button"
        v-on="on"
      >
        {{ $t(`support.${camelize(buttonName)}`) }}
      </v-btn>
      <div data-cy="created-requests">
        {{ $tc('support.requestsCount_requestsCreated', requestsCount) }}
      </div>
    </template>
    <v-card>
      <v-card-title>
        <span
          v-t="submitted ? 'support.thankYouForYourRequest' :'support.callbackRequest'"
          class="headline"
          data-cy="request-callback-modal"
        />
        <v-spacer />
        <v-icon
          data-cy="request-callback-modal-close"
          @click="closeModal()"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <core-element-created-message v-if="submitted" />
        <material-request-callback-form v-else-if="dialog" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  props: {
    buttonName: {
      type: String,
      default: 'Request callback'
    }
  },
  data () {
    return {
      dialog: false,
      submitted: false
    }
  },
  computed: {
    ...mapState('support', ['loading', 'showApiErrors']),
    ...mapGetters('support', ['getNumberOfRequestsById']),
    requestsCount () {
      return this.getNumberOfRequestsById(this.$route.params.clientId)
    }
  },
  watch: {
    loading (val, oldVal) {
      if (!val && val !== oldVal && !this.showApiErrors) {
        this.submitted = true
      }
    }
  },
  methods: {
    ...mapMutations('support', ['setErrors']),
    camelize (string) {
      return this.$options.filters.camelize(string)
    },
    closeModal () {
      this.dialog = false
      this.onDialogInput()
    },
    onDialogInput () {
      if (this.dialog) {
        this.setErrors({})
        this.submitted = false
      }
    }
  }
}
</script>
